//import React from 'react';
//import { Semaphore } from '../../components';
//import RepTargetCardHelp from '../../components/Cards/CardDetails/RepTargetCardHelp';
import { REACT_APP_SNOWFLAKE_DATABASE_NAME } from '../../constants/environment';
//import getLight from './getLight';

const getRepTargetCard = (
  repTarget,
  selectedTableGroupName,
  selectedTableName
) => {
  const cardDetails = [];
  if (repTarget?.type === 'S3') {
    cardDetails.push(
      {
        label: 'Region',
        description: repTarget?.targetOptions?.region
      },
      {
        label: 'ACL',
        description: repTarget?.targetOptions?.acl
          .toLowerCase()
          .replace(/_/g, '-')
      },
      {
        label: 'URI',
        description: repTarget?.targetOptions?.bucketUrl
      }
    );
  } else if (repTarget?.type === 'SNOWFLAKE') {
    const settings = repTarget?.sink?.sink?.settings
      ? repTarget?.sink?.sink?.settings[repTarget?.sink?.sink?.settings?.type]
      : {};
    cardDetails.push(
      {
        label: 'Warehouse',
        description: settings?.warehouse || REACT_APP_SNOWFLAKE_DATABASE_NAME
      },
      {
        label: 'DB',
        description: settings?.database || REACT_APP_SNOWFLAKE_DATABASE_NAME
      },
      {
        label: 'Schema',
        description: settings?.schema || selectedTableGroupName
      },
      {
        label: 'Table',
        description:
          settings?.table ||
          `UMD_${selectedTableGroupName}__${selectedTableName}`
      }
    );
  }
  /*
  if (repTarget?.source?.source) {
    cardDetails.push({
      label: 'Source',
      helper: <RepTargetCardHelp title="Source" />,
      description: (
        <Semaphore
          text={repTarget?.source?.source?.status?.state}
          getLight={getLight}
        />
      )
    });
  }
  if (repTarget?.sink?.sink) {
    cardDetails.push({
      label: 'Sink',
      helper: <RepTargetCardHelp isSink title="Sink" />,
      description: (
        <Semaphore
          text={repTarget?.sink?.sink?.status?.state}
          getLight={getLight}
        />
      )
    });
  }
  */
  return cardDetails;
};

export default getRepTargetCard;
