import React from 'react';
import { Spinner, Wave, Button, RouterLink } from '../../components/index';

import { APP_HELP_URL, APP_SUPPORT_URL } from '../../constants/defaultValues';
import SecureComponent from '../../components/SecureComponent/SecureComponentContainer';

import { I18n } from 'react-redux-i18n';
import './BannerStyles.css';

const WaveWrapper = props => (
  <Wave style={{ width: '100%', height: '90vh', overflow: 'hidden' }}>
    <Spinner
      title={props.title}
      ellipsesOuterThinClassName="ellipses ellipses-outer--thin ellipses-outer--thin--slow"
      ellipsesOuterThickClassName="ellipses ellipses-outer--thick ellipses-outer--thick--slow"
    />
  </Wave>
);

const Banner = ({ message, subMessage }) => {
  return (
    <div className="banner">
      <div
        className="message"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
      <div
        className="sub-message"
        dangerouslySetInnerHTML={{ __html: subMessage }}
      ></div>
    </div>
  );
};

const styles = { style: { margin: '20px', minWidth: '200px' } };
const tealTheme = { primary: 'black', secondary: 'white' };

const Launchpad = props => {
  return (
    <SecureComponent>
      <div className="bootstrap-row">
        <div className="bootstrap-col-md-2">
          <RouterLink href={'/tablegroup'}>
            <Button
              data-testid="data-manager-button"
              {...styles}
              theme={tealTheme}
            >
              Data Manager
            </Button>
          </RouterLink>
        </div>
        {props.isAdmin === true && (
          <div className="bootstrap-col-md-2">
            <RouterLink href={'/tablegroup/form'}>
              <Button {...styles} theme={tealTheme}>
                Add Table Group
              </Button>
            </RouterLink>
          </div>
        )}
        <div className="bootstrap-col-md-2">
          <a href={APP_HELP_URL} target="_blank" rel="noopener noreferrer">
            <Button {...styles} theme={tealTheme}>
              Documentation
            </Button>
          </a>
        </div>
        <div className="bootstrap-col-md-2">
          <a href={APP_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
            <Button {...styles} theme={tealTheme}>
              Support
            </Button>
          </a>
        </div>
      </div>

      <div>
        <Banner
          message={I18n.t('launchpadBanner.bannerText')}
          subMessage={I18n.t('launchpadBanner.bannerSubText')}
        />
        {/* Other components and content */}
      </div>
      <WaveWrapper title="NIKE USER MANAGED DATA" />
    </SecureComponent>
  );
};

export default Launchpad;
